<template>
  <b-modal
    ref="modalElection"
    :title="`${actionType === 'add' ? 'Tambah' : 'Update'} election`"
    ok-title="Simpan"
    cancel-title="Batalkan"
    :no-close-on-backdrop="true"
    @hide="closeModal"
    @ok="submit"
  >
    <ValidationObserver
      ref="formElection"
      tag="form"
    >
      <b-form-group
        label="Name"
        label-for="name"
      >

        <ValidationProvider
          #default="{ errors }"
          name="name"
          rules="required"
        >
          <b-form-input
            id="name"
            ref="form-name"
            v-model="form.name"
            placeholder="name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Date Start"
        label-for="date-start"
      >

        <ValidationProvider
          #default="{ errors }"
          name="Date Start"
          rules="required"
        >
          <flat-pickr
            id="date-start"
            ref="form-start_at"
            v-model="form.start_at"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'd-m-Y H:m', time_24hr: true}"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Date End"
        label-for="date-end"
      >

        <ValidationProvider
          #default="{ errors }"
          name="Date End"
          rules="required"
        >
          <flat-pickr
            id="date-end"
            ref="form-finish_at"
            v-model="form.finish_at"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'd-m-Y H:m', time_24hr: true}"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Faculties"
        label-for="date-end"
      >

        <ValidationProvider
          #default="{ errors }"
          name="Faculties"
          :rules="actionType === 'add' ? 'required' : ''"
        >
          <v-select
            ref="form-faculties"
            v-model="form.faculties"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="faculties.selectOptions"
            :reduce="faculty => faculty.id"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        v-if="actionType === 'update'"
        label="Status"
        label-for="status"
      >

        <ValidationProvider
          #default="{ errors }"
          name="status"
          rules="required"
        >
          <b-form-select
            id="status"
            ref="form-status"
            v-model="form.status"
            :options="[{ value: '0', text: 'belum selesai' },
                       { value: '1', text: 'selesai' },]"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import { required } from '@validations'
import FlatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import API from '@/api'
import { formatDateToDefault } from '../Election.vue'

export default {

  components: {
    FlatPickr,
    vSelect,
    BFormSelect,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: String,
      default: 'add',
      validator(value) {
        // The value must match one of these strings
        return ['add', 'update'].indexOf(value) !== -1
      },
    },

    autoFillForm: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        start_at: '', // dd-MM-yyyy HH:mm
        finish_at: '', // dd-MM-yyyy HH:mm
        faculties: [],
        status: 0,
      }),
    },

  },

  data() {
    return {
      form: {
        id: null,
        name: '',
        start_at: '',
        finish_at: '',
        faculties: [],
        status: 0,
      },
      faculties: {
        data: [],
        selectOptions: [],
        fetching: 'idle',
      },
      required,
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.actionType === 'update') {
          this.form = this.autoFillForm
        }
        this.$refs.modalElection.show()
      }
    },
  },
  created() {
    this.fetchFaculties()
  },
  mounted() {
    // this.$refs.modalElection.show()
  },
  methods: {
    closeModal() {
      this.$emit('update:is-open', false)
    },

    resetForm() {
      this.form = {
        id: null,
        name: '',
        start_at: '',
        finish_at: '',
        faculties: [],
        status: 0,
      }
    },

    async add() {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menambahkan election?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          const {
            name, start_at, finish_at, faculties,
          } = this.form
          await API.election.createElection({
            name,
            start_at: `${formatDateToDefault(start_at)}:00`,
            finish_at: `${formatDateToDefault(finish_at)}:00`,
            faculties,
          })
          this.$emit('actionDone')
          this.$refs.modalElection.hide()
          this.resetForm()
          this.$_handleMsgSuccess('Berhasil menambahkan election')
        } catch (error) {
          this.$_handleMsgErrors(error, 'Gagal menambahkan election')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },

    async update() {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menyimpan perubahan?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          const {
            name, start_at, finish_at, faculties, id,
          } = this.form
          await API.election.updateElection({
            id,
            name,
            start_at: `${formatDateToDefault(start_at)}:00`,
            finish_at: `${formatDateToDefault(finish_at)}:00`,
            faculties,
          })
          this.$emit('actionDone')
          this.$refs.modalElection.hide()
          this.resetForm()
          this.$_handleMsgSuccess('Berhasil menyimpan perubahan election')
        } catch (error) {
          this.$_handleMsgErrors(error, 'Gagal menyimpan perubahan election')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },

    async submit(e) {
      e.preventDefault()
      if (await this.$_checkValidateOfForm('formElection')) {
        this[this.actionType]()
      }
    },

    async fetchFaculties() {
      try {
        this.faculties.fetching = 'pending'
        const { data } = await API.master.getFakultas('id_universitas=1')
        this.faculties.data = data
        this.faculties.selectOptions = data.data.map(item => ({ ...item, label: `${item.nama_fakultas} - ${item.universitas.nama_universitas}` }))
        this.faculties.fetching = 'resolved'
      } catch (error) {
        console.log(error)
        this.faculties.fetching = 'rejected'
      }
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
