<template>
  <div>

    <b-card>
      <b-row>
        <!-- <b-col /> -->
        <b-col
          cols="12"
          class="mb-1"
        >
          <b-button
            variant="gradient-success"
            class="mr-sm-25"
            size="sm"
            @click="typeActionModal = 'add', isOpenModal = true"
          >
            <feather-icon icon="PlusIcon" /> Tambah election
          </b-button>
        </b-col>
        <b-col cols="12">
          <h5 class="font-weight-bold">
            Filter
          </h5>
        </b-col>
        <b-col lg="3">
          <!--  -->
          <b-form-group
            label="Searh"
            label-for="search"
          >
            <b-form-input
              id="search"
              v-model="filters.search"
              placeholder="Nama"
            />
          </b-form-group>

        </b-col>
        <b-col lg="3">
          <!--  -->
          <b-form-group
            label="Date Start"
            label-for="date-start"
          >
            <flat-pickr
              id="date-start"
              v-model="filters.start_at"
              class="form-control"
              :config="{ enableTime: true,dateFormat: 'd-m-Y H:m', time_24hr: true}"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <!--  -->
          <b-form-group
            label="Date End"
            label-for="date-end"
          >
            <flat-pickr
              id="date-end"
              v-model="filters.finish_at"
              class="form-control"
              :config="{ enableTime: true,dateFormat: 'd-m-Y H:m', time_24hr: true}"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <!--  -->
          <b-form-group
            label="Bersihkan filter"
            label-for="bersihkan-filter"
          >
            <b-button
              variant="gradient-success"
              class="btn-icon mr-sm-25"
              size="sm"
              @click="clearFilter"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :pagination-options="{
              enabled: true,
            }"
            :rows="rows"
            :columns="columns"
            :sort-options="{
              enabled: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <span v-if="props.column.field === 'status'">
                {{ statusText(props.row.status) }}
              </span>

              <span v-else-if="props.column.field === 'action'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon mr-sm-25"
                  size="sm"
                  @click="preUpdate(props.row)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  variant="gradient-warning"
                  class="btn-icon"
                  size="sm"
                  @click="deleteElection(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>

              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>

            <!-- Column: Common -->

            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
    <ModalElection
      :is-open.sync="isOpenModal"
      :action-type="typeActionModal"
      :auto-fill-form="dataModalForm"
      @actionDone="loadItems"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { debounce } from 'debounce'
import FlatPickr from 'vue-flatpickr-component'
import API from '@/api'
import ModalElection from './components/ModalElection.vue'

export const formatDateToDefault = arg => {
  if (!arg) {
    return ''
  }
  const dateAndTime = arg.split(' ')
  const date = dateAndTime[0].split('-').reverse().join('-')
  const time = dateAndTime[1]

  return `${date} ${time}`
}

export const joinToQuery = (key, value) => {
  if (!value) {
    return ''
  }

  return `${key}=${value}`
}

export const convertDateToLocal = arg => { // 'yyyy-MM-dd HH:mm:ss' to  'dd-MM-yyyy HH:mm'
  console.log('before', arg)
  if (!arg) {
    return ''
  }
  const date = new Date(arg)
  const [month, day, year] = [
    (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : date.getMonth() + 1,
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
    date.getFullYear(),
  ]
  const [hour, minutes] = [
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
  ]
  console.log('after', `${day}-${month}-${year} ${hour}:${minutes}`)

  return `${day}-${month}-${year} ${hour}:${minutes}`
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    FlatPickr,
    BButton,
    ModalElection,
  },
  data() {
    return {
      isLoading: false,
      columns: [
      // Name, Faculty, Date Start, Date End, Created At, Updated At
        {
          label: 'Nama',
          field: 'name',
        },
        {
          label: 'Faculty',
          field: this.fieldFaculty,
        },
        {
          label: 'status',
          field: 'status',
        },
        {
          label: 'Date Start',
          field: 'start_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Date End',
          field: 'finish_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Created At',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Updated At',
          field: 'updated_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      filters: {
        search: '',
        start_at: '',
        finish_at: '',
      },
      isOpenModal: false,
      typeActionModal: 'add',
      dataModalForm: null,
    }
  },
  computed: {
    statusText() {
      const text = {
        0: 'Belum Selesai',
        1: 'Selesai',
      }

      return status => text[status]
    },

  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.onFilters()
      },
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    fieldFaculty(obj) {
      const result = obj.faculties.map(item => item.faculty_id).join(', ')
      return result
    },
    clearFilter() {
      this.filters = {
        search: '',
        start_at: '',
        finish_at: '',
      }
    },
    // eslint-disable-next-line func-names
    onFilters: debounce(function () {
      this.loadItems()
    }, 200),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.isLoading = true
        // start_at=2018-08-08 00:00:00&finish_at=2018-08-08 00:00:00&searh=iluni
        const { start_at, finish_at, search } = this.filters
        let qs = joinToQuery('start_at', formatDateToDefault(start_at))
        qs += joinToQuery('finish_at', formatDateToDefault(finish_at))
        qs += joinToQuery('search', search)
        // qs += joinToQuery('limit', this.serverParams.perPage)
        // qs += joinToQuery('page', this.serverParams.page)
        const { data } = await API.election.getElection(qs)
        this.totalRecords = data.total
        this.rows = data.data
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },
    preUpdate(election) {
      const form = {
        id: election.id,
        name: election.name,
        start_at: convertDateToLocal(election.start_at),
        finish_at: convertDateToLocal(election.finish_at),
        faculties: election.faculties.map(item => item.faculty_id),
        status: election.status,
      }
      this.dataModalForm = form
      this.typeActionModal = 'update'
      this.isOpenModal = true
    },
    async deleteElection(id) {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menghapus election?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          await API.election.deleteElection(id)
          this.loadItems()
          this.$_handleMsgSuccess('Berhasil menghapus election')
        } catch (error) {
          this.$_handleMsgErrors(error, 'Gagal menghapus election')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
