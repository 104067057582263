var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalElection",attrs:{"title":((_vm.actionType === 'add' ? 'Tambah' : 'Update') + " election"),"ok-title":"Simpan","cancel-title":"Batalkan","no-close-on-backdrop":true},on:{"hide":_vm.closeModal,"ok":_vm.submit}},[_c('ValidationObserver',{ref:"formElection",attrs:{"tag":"form"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"form-name",attrs:{"id":"name","placeholder":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Date Start","label-for":"date-start"}},[_c('ValidationProvider',{attrs:{"name":"Date Start","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{ref:"form-start_at",staticClass:"form-control",attrs:{"id":"date-start","config":{ enableTime: true,dateFormat: 'd-m-Y H:m', time_24hr: true}},model:{value:(_vm.form.start_at),callback:function ($$v) {_vm.$set(_vm.form, "start_at", $$v)},expression:"form.start_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Date End","label-for":"date-end"}},[_c('ValidationProvider',{attrs:{"name":"Date End","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{ref:"form-finish_at",staticClass:"form-control",attrs:{"id":"date-end","config":{ enableTime: true,dateFormat: 'd-m-Y H:m', time_24hr: true}},model:{value:(_vm.form.finish_at),callback:function ($$v) {_vm.$set(_vm.form, "finish_at", $$v)},expression:"form.finish_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Faculties","label-for":"date-end"}},[_c('ValidationProvider',{attrs:{"name":"Faculties","rules":_vm.actionType === 'add' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"form-faculties",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","options":_vm.faculties.selectOptions,"reduce":function (faculty) { return faculty.id; }},model:{value:(_vm.form.faculties),callback:function ($$v) {_vm.$set(_vm.form, "faculties", $$v)},expression:"form.faculties"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.actionType === 'update')?_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('ValidationProvider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{ref:"form-status",attrs:{"id":"status","options":[{ value: '0', text: 'belum selesai' },
                     { value: '1', text: 'selesai' } ]},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,917868254)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }